<template>
  <div>
    <!-- section-1  -->
    <v-card class="bgs rounded-0" flat>
      <div class="" id="home-tops">
        <v-row align="center" class="ma-0">
          <v-col cols="12" md="6" sm="12" class="">
            <div class="left">
              <p class="text-lg-h4 text-sm-h6 text-body-1 font-weight-bold" style="letter-spacing: 2px">
                {{ $t("适用于任何项目的专业主题网站模板") }}
              </p>

              <p class="mt-0 text-body-2 mb-8">
                {{ $t("根据自己的需求选择合适的模板，并在其基础上进行自定义和修改，以满足自己的品牌形象和功能要求。") }}
              </p>
              <div class="mt-8">

                <v-card class="d-flex rounded-xl" height="50" flat>
                  <input type="text" :placeholder="$t('搜索您想要的模版')" v-model="search_keyword" class="pl-2" @keyup.enter="searchList()">

                  <v-btn color="success" class="primary-background px-12" rounded height="50" @click="searchList()">{{ $t('搜索') }}</v-btn>
                </v-card>
              </div>
            </div>
          </v-col>

          <v-col cols="12" md="6" sm="12" class="px-0 d-flex justify-end py-10">
            <div>
              <v-img class="gif" max-width="595" src="@/assets/images/template/banner-right.png"></v-img>

            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card color="#F7F7F7" flat class="py-12">


      <v-container>
        <p class="font32 font-weight-bold text-center">
          {{ $t("最新的主题和模板") }} <v-btn color="#F2F2F2" class="black--text" rounded depressed small to="/template-list">{{$t('查看更多') }}</v-btn>
        </p>

        <v-row class="ma-0">
          <v-col cols="6" md="3" sm="4" class="" v-for="(mitem,midx) in mobanlist" :key="midx">
            <v-card flat class="rounded-lg" :href="'/template-detail?themes_id='+mitem.themes_id">

              <v-img :src="mitem.cover_img" alt="">
                <v-img src="@/assets/images/template/icon-buyed.png"
                 alt="" 
                class="ml-4 text-center"
                width="41"
                height="45"
                v-if="mitem.is_buy==1"
                >
                      <span class="buyed-text">buyed</span>
                    </v-img>
              </v-img>

              <p class="font18  my-2 px-2">{{ mitem.name }}</p>
              <p class="text--secondary text-body-2 px-2 text-no-wrap text-truncate">
               {{ mitem.des }}
              </p>
              <div class="d-flex justify-space-between align-center px-2 pb-4">
                <span class="text-h6 font-weight-bold">{{ mitem.symbol }}{{ mitem.price }}</span>
                <v-btn color="#F2F2F2" class="black--text" rounded depressed small :to="'/template-detail?themes_id='+mitem.themes_id">{{ $t('查看详情')}}</v-btn>
              </div>
            </v-card>
          </v-col>

        </v-row>
      </v-container>
    </v-card>

    <v-container class="py-12">
      <p class="mb-11 font32 text-center font-weight-bold">
        {{ $t("本周精选创作者") }}
      </p>
      <v-row>
        
        <v-col cols="12" lg="6" md="6" v-for="n in authorList" :key="n.admin_id">
          <v-card color="#F7F7F7" class="pa-6 rounded-lg" flat>
            <div class="d-flex justify-space-between align-center mb-6">
              <div class="d-flex flex-1 align-center">
                <v-avatar size="60">
                  <img :src="n.avatar" alt="alt">
                </v-avatar>
                <div class="pl-4">
                  <p class="text-h6 font-weight-bold">{{ n.nickname }}</p>
                  <!-- <p class="text-no-wrap text-truncate mb-0 grey--text text--darken-3" style="max-width: 150px;">
                    张三简介张三简介张三简介</p> -->
                </div>

              </div>
              <!-- <v-btn color="#fff" class="primary--text" rounded depressed small>{{ $t('查看详情') }}</v-btn> -->
            </div>
            <v-row>
              <template v-for="(item,idx) in n.themes_info" >
              
              <v-col cols="6" v-if="idx<2" :key="item.themes_id">
                <v-card flat class="rounded-lg" :href="'/template-detail?themes_id='+item.themes_id">

                  <v-img :src="item.cover_img" alt="">
                    <!-- <v-img src="@/assets/images/template/icon-buyed.png" alt="" class="ml-4 text-center" width="41"
                      height="45">
                      <span class="buyed-text">buyed</span>
                    </v-img> -->
                  </v-img>

                  <p class="font18  my-2 px-2">{{ item.name }}</p>
                  <p class="text--secondary text-body-2 px-2 text-no-wrap text-truncate">
                    {{ item.des }}
                  </p>
                  <div class="d-flex justify-space-between align-center px-2 pb-4">
                    <span class="text-h6 font-weight-bold">{{item.symbol}}{{ item.price }}</span>
                    <v-btn color="#F2F2F2" class="black--text" rounded depressed small>{{ $t('查看详情') }}</v-btn>
                  </div>
                </v-card>
              </v-col>
            </template>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-card color="#333333" flat class="py-12">
      <v-container>
        <v-row>
          <v-col cols="6" lg="3" md="3" class="offset-lg-3 offset-md-3">
            <v-card flat class="rounded-lg">
              <img src="@/assets/images/template/icon-ad1.png" alt="" width="100%">


              <p class="font18  my-2 px-2">{{ $t('数百万创意资源、无限下载') }}</p>
              <p class="text--secondary text-body-2 px-2 pb-4 text-no-wrap text-truncate">
                {{ $t('立即订阅、可随时取消') }}
              </p>

            </v-card>

          </v-col>
          <v-col cols="6" lg="3" md="3">
            <v-card flat class="rounded-lg">
              <img src="@/assets/images/template/icon-ad2.png" alt="" width="100%">


              <p class="font18  my-2 px-2">{{ $t('轻松的设计有您自己在线制作') }}</p>
              <p class="text--secondary text-body-2 px-2 pb-4 text-no-wrap text-truncate">
                {{ $t('适合任何技能水平的智能模板') }}
              </p>

            </v-card>

          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
  
<script>
export default {
  name: "Home",
  data() {
    return {
      mobanlist: [],
      getparam: {
        current_page: 1,
        page_number:10
      },
      authorList:[],//精选作者列表
      search_keyword:''
    }
  },
  mounted() {
    this.getlist();
    this.getAuthorList();
  },
  methods: {
    getlist() {
      this.$api.template.list(this.getparam).then(res => {
        if (res.status) {
          this.mobanlist = res.data.records;

        }
      });
    },
    getAuthorList(){
      this.$api.template.author_list({current_page: 1,page_number:2}).then(res=>{
        if(res.status){
          this.authorList=res.data.records;
        }
      })
    },
    searchList(){
      this.$router.push('/template-list?keyword='+this.search_keyword)
    }
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 1445px) {
  #home-tops {
    width: 1445px !important;
    margin: 0 auto;

    .left {
      margin-left: 120px;
    }
  }
}

.color1 {
  background: #fafafa;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.color2 {
  background: #f2f2f2;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buyed-text {
  bottom: 7px;
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  right: 0;
  color: #fff;
  transform: scale(0.8);
}
</style>